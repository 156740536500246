<template lang="html">
  <div>
    <h4>Parameters: </h4>
    <LabelAndMessage>
      <template #label>
        <IxRes>phbb.samplingOrderOverview.labels.labName</IxRes>:
      </template>
      {{ value.labName }}
    </LabelAndMessage>
    <LabelAndMessage>
      <template #label>
        <IxRes>phbb.samplingOrderOverview.labels.created</IxRes>:
      </template>
      {{ $i18n.format(value.created, 'datetime-short') }}
    </LabelAndMessage>
    <LabelAndMessage>
      <template #label>
        <IxRes>phbb.samplingOrderOverview.labels.probingMethod</IxRes>:
      </template>
      {{ value.probingMethod }}
    </LabelAndMessage>
    <hr>
    <SimpleTable :columns="columns" :data="tableData" localize-headers>
      <template v-if="value.targetValue === 'humus'" #humus="{row}">
        <NumericInput
          name="humus"
          :value="row.humus"
          percent
          percent-not-normalized
          :disabled="disabled"
          @input="onInput(value.id, row.samplePointId, 'humus', $event)"
        />
      </template>
      <template v-if="value.targetValue === 'ph'" #ph="{row}">
        <NumericInput
          name="ph"
          :value="row.ph"
          :disabled="disabled"
          @input="onInput(value.id, row.samplePointId, 'ph', $event)"
        />
      </template>
      <template v-if="value.targetValue === 'soilTexture'" #sand="{row}">
        <NumericInput
          name="sand"
          :value="row.sand"
          percent
          :disabled="disabled"
          percent-not-normalized
          @input="onInput(value.id, row.samplePointId, 'sand', $event)"
        />
      </template>
      <template v-if="value.targetValue === 'soilTexture'" #silt="{row}">
        <NumericInput
          name="silt"
          :value="row.silt"
          percent
          :disabled="disabled"
          percent-not-normalized
          @input="onInput(value.id, row.samplePointId, 'silt', $event)"
        />
      </template>
      <template v-if="value.targetValue === 'soilTexture'" #clay="{row}">
        <NumericInput
          name="clay"
          :value="row.clay"
          percent
          :disabled="disabled"
          percent-not-normalized
          @input="onInput(value.id, row.samplePointId, 'clay', $event)"
        />
      </template>
    </SimpleTable>
  </div>
</template>

<script>
import SimpleTable from '@components/table/SimpleTable'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import NumericInput from '@components/forms/NumericInput'

export default {
  components: {NumericInput, LabelAndMessage, SimpleTable},
  props: {
    value: Object,
    disabled: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    columns () {
      switch (this.value.targetValue) {
      case 'humus': {
        return {
          sampleId: 'phbb.samplingOrderOverview.columns.sampleId',
          humus: 'phbb.samplingOrderOverview.columns.humus'
        }
      }
      case 'ph': {
        return {
          sampleId: 'phbb.samplingOrderOverview.columns.sampleId',
          ph: 'phbb.samplingOrderOverview.columns.pH'
        }
      }
      case 'soilTexture': {
        return {
          sampleId: 'phbb.samplingOrderOverview.columns.sampleId',
          sand: 'phbb.samplingOrderOverview.columns.sand',
          silt: 'phbb.samplingOrderOverview.columns.silt',
          clay: 'phbb.samplingOrderOverview.columns.clay'
        }
      }
      default: {
        return {
          sampleId: 'phbb.samplingOrderOverview.columns.sampleId'
        }
      }
      }
    },
    tableData () {
      return this.value.features.map(feature => feature.properties)
    }
  },
  methods: {
    onInput (featureId, samplePointId, type, value) {
      this.$emit('input', {featureId, samplePointId, type, value})
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
