<template lang="html">
  <div class="order-administration-content">
    <Header target="phbb">
      <template #back-title>
        <IxRes>phbb.buttons.backToPhbb</IxRes>
      </template>
      <template #title>
        <IxRes>phbb.samplingOrder.header.samplingOrders</IxRes>
      </template>
    </Header>

    <div class="order-administration">
      <VueSelectize
        v-model="selectedOrderId"
        :options="orderIds"
        :fields="fields"
        name="orderSelection"
        allow-empty
      >
        <template #label>
          <IxRes>phbb.create.samplingOrder.labels.availableOrders</IxRes>
        </template>
      </VueSelectize>

      <div class="buttons">
        <div>
          <IxButton
            large
            @click="createOrder"
          >
            <IxRes>phbb.create.samplingOrder.buttons.createOrder</IxRes>
          </IxButton>
        </div>

        <div>
          <IxButton
            download large
            :disabled="!selectedOrderId"
            @click="exportOrder"
          >
            <IxRes>phbb.create.samplingOrder.buttons.downloadOrder</IxRes>
          </IxButton>
          <a
            ref="anchor_shape" style="display: none"
            type="application/zip"
            target="_blank" data-ajax="false"
          />
        </div>

        <div>
          <IxButton
            remove large
            :disabled="!selectedOrderId"
            @click="deleteOrder"
          >
            <IxRes>phbb.create.samplingOrder.buttons.deleteOrder</IxRes>
          </IxButton>
        </div>
      </div>
    </div>

    <div v-if="selectedOrderData" class="order-note">
      <TextInput
        name="note"
        :value="note"
        multiline
        disabled
      >
        <template #label>
          <IxRes>phbb.create.samplingOrder.labels.orderNote</IxRes>
        </template>
      </TextInput>
    </div>

    <div v-if="selectedOrderData" class="order-overview">
      <div>
        <SamplingOrderOverview
          :value="selectedOrderData"
          @input="onInput"
          :disabled="!isEditable"
        />
      </div>
      <div class="buttons">
        <div>
          <IxButton
            save large
            :disabled="!updatedMeasurements"
            @click="updateValues"
          >
            <IxRes>phbb.create.samplingOrder.buttons.updateValues</IxRes>
          </IxButton>
        </div>
        <div>
          <IxButton
            refresh large
            :disabled="!updatedMeasurements"
            @click="resetChanges"
          >
            <IxRes>phbb.create.samplingOrder.buttons.resetChanges</IxRes>
          </IxButton>
        </div>
      </div>
    </div>

    <FrsLoadingIndicator
      :requests="[
        'phbb.order.delete',
        'phbb.order.details.update',
        'phbb.order-result.orders.details',
        'phbb.order-result.orders',
        'phbb.order.export'
      ]"
    />

    <ProbingPointsLayer
      :features="pointFeatures"
      :field-feature="[]"
    />
  </div>
</template>

<script>
import {keyBy} from 'lodash'
import {smarterGet, smarterPost, smarterDelete} from '@helpers/vuex/data-loading'

import {notifications} from 'src/js/infrastructure'
import Header from '../../widgets/Header'
import IxButton from '@components/IxButton'
import VueSelectize from '@components/VueSelectize'
import TextInput from '@components/forms/TextInput'

import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import SamplingOrderOverview from './SamplingOrderOverview'
import ProbingPointsLayer from '@frs/components/phbb/widgets/ProbingPointsLayer'

import ChangeDetectionMixin from 'src/vue/components/forms/ChangeDetectionMixin'

export default {
  components: {
    IxButton,
    VueSelectize,
    Header,
    TextInput,
    FrsLoadingIndicator,
    SamplingOrderOverview,
    ProbingPointsLayer
  },
  mixins: [
    ChangeDetectionMixin
  ],
  props: {
    fieldId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      selectedOrderId: null,
      availableOrders: null,
      orderDataLookup: null,
      updatedMeasurements: null
    }
  },
  computed: {
    isEditable () {
      return (this.selectedOrderId in this.orderDataLookup) && (this.orderDataLookup[this.selectedOrderId].probingMethod !== null)
    },
    orderLookup () {
      return keyBy(this.availableOrders, 'id')
    },
    orderIds () {
      return Object.keys(this.orderLookup)
    },
    selectedOrderData () {
      if (!this.selectedOrderId || !this.orderDataLookup) return
      if (!this.orderDataLookup[this.selectedOrderId]) return

      return this.orderDataLookup[this.selectedOrderId]
    },
    note () {
      if (!this.selectedOrderData) return this.$i18n.translate('phbb.create.samplingOrder.description.hint')
      return this.selectedOrderData.note
    },
    pointFeatures () {
      if (!this.selectedOrderData) return []
      return this.orderDataLookup[this.selectedOrderId].features
    },
    fields () {
      return {
        text: id => this.orderLookup[id].name,
        label: id => this.$i18n.format(this.orderLookup[id].created, 'datetime-short'),
        value: id => id
      }
    }
  },
  methods: {
    onInput ({featureId, samplePointId, type, value}) {
      // add changed measurement values
      if (this.updatedMeasurements && (samplePointId in this.updatedMeasurements)) {
        const i = this.updatedMeasurements[samplePointId].findIndex(x => x.type === type)

        if (i < 0) {
          this.updatedMeasurements[samplePointId].push({type: type, value: value})
        } else {
          this.updatedMeasurements[samplePointId].splice(i, 1, {type: type, value: value})
        }
      } else {
        this.updatedMeasurements = Object.assign(this.updatedMeasurements || {}, {[samplePointId]: [{type: type, value: value}]})
      }

      // udpate value by reference
      const feature = this.orderDataLookup[featureId].features.find(feature => feature.properties.samplePointId === samplePointId)
      feature.properties[type] = value
    },
    async updateValues () {
      try {
        await smarterPost('/api/v2/phbb/orders/{orderId}/measurements/update', {measurementsBySamplePointId: this.updatedMeasurements}, {
          id: 'phbb.order.details.update',
          inputs: {
            orderId: () => this.selectedOrderId
          },
          onResult: () => {
            this.getOrderDetails(this.selectedOrderId)
            this.updatedMeasurements = null
            notifications.success(this.$i18n.translate('phbb.samplingOrder.updatevalues.success'))
            this.reset(true) // reset change detection from inputs
          }
        })
      } catch (error) {
        notifications.error(this.$i18n.translate('phbb.samplingOrder.updatevalues.error'))
        throw error
      }
    },
    resetChanges () {
      if (!this.selectedOrderId) return

      this.getOrderDetails(this.selectedOrderId)

      this.updatedMeasurements = null
      this.reset(true) // reset change detection from inputs
    },
    async getOrderDetails (id) {
      try {
        await smarterGet('/api/v2/phbb/orders/{orderId}', {
          id: 'phbb.order-result.orders.details',
          inputs: {
            orderId: () => id
          },
          onResult: details => {
            this.orderDataLookup = {
              [id]: details
            }
          }
        })
      } catch (error) {
        notifications.error(this.$i18n.translate('phbb.samplingOrder.getOrderDetails.error'))
        throw error
      }
    },
    loadOrders () {
      try {
        smarterGet('/api/v2/phbb/orders/basic/{fieldId}/{harvestYear}', {
          id: 'phbb.order-result.orders',
          inputs: {
            fieldId: () => this.$route.params.fieldId,
            harvestYear: () => this.$route.params.year
          },
          onResult: orders => {
            this.availableOrders = orders
          }
        })
      } catch (error) {
        notifications.error(this.$i18n.translate('phbb.samplingOrder.loadOrders.error'))
        throw error
      }
    },
    createOrder () {
      this.$router.push({name: 'createPhBbOrder'})
    },
    async exportOrder () {
      try {
        const path = await smarterPost('/api/v2/phbb/orders/{fieldId}/{harvestYear}/{orderId}/export',null, {
          id: 'phbb.order.export',
          inputs: {
            orderId: () => this.selectedOrderId,
            harvestYear: () => this.$route.params.year,
            fieldId: () => this.$route.params.fieldId
          }
        })
        this.$refs.anchor_shape.href = `/Download/Temp/${path}`
        this.$refs.anchor_shape.download = path.split(/\\/)[1]
        this.$refs.anchor_shape.click()
      } catch (error) {
        notifications.error(this.$i18n.translate('phbb.samplingOrder.exportOrder.error'))
        throw error
      }
    },
    async deleteOrder () {
      try {
        await smarterDelete('/api/v2/phbb/orders/{selectedOrderId}', {
          id: 'phbb.order.delete',
          inputs: {
            selectedOrderId: () => this.selectedOrderId
          },
          onResult: () => {
            notifications.success(this.$i18n.translate('phbb.samplingOrder.delete.success'))
            this.selectedOrderId = null
            this.loadOrders()
          }
        })
      } catch (error) {
        notifications.error(this.$i18n.translate('phbb.samplingOrder.delete.error'))
        throw error
      }
    }
  },
  watch: {
    selectedOrderId (id) {
      if (!id) return

      this.getOrderDetails(id)
    }
  },
  created () {
    this.loadOrders()
  }
}

</script>

<style lang="scss" scoped>
.order-administration {
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;

  :nth-child(1), :nth-child(2) {
    margin-right: 5px;
  }
}

.order-note, .order-administration {
  margin: 5px;
}

.order-overview {
  display: flex;
  flex-direction: column;
  margin: 5px 5px 20px 5px;
}
</style>
