var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',[_vm._v("Parameters: ")]),_c('LabelAndMessage',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('IxRes',[_vm._v("phbb.samplingOrderOverview.labels.labName")]),_vm._v(":\n    ")]},proxy:true}])},[_vm._v("\n    "+_vm._s(_vm.value.labName)+"\n  ")]),_c('LabelAndMessage',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('IxRes',[_vm._v("phbb.samplingOrderOverview.labels.created")]),_vm._v(":\n    ")]},proxy:true}])},[_vm._v("\n    "+_vm._s(_vm.$i18n.format(_vm.value.created, 'datetime-short'))+"\n  ")]),_c('LabelAndMessage',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('IxRes',[_vm._v("phbb.samplingOrderOverview.labels.probingMethod")]),_vm._v(":\n    ")]},proxy:true}])},[_vm._v("\n    "+_vm._s(_vm.value.probingMethod)+"\n  ")]),_c('hr'),_c('SimpleTable',{attrs:{"columns":_vm.columns,"data":_vm.tableData,"localize-headers":""},scopedSlots:_vm._u([(_vm.value.targetValue === 'humus')?{key:"humus",fn:function(ref){
var row = ref.row;
return [_c('NumericInput',{attrs:{"name":"humus","value":row.humus,"percent":"","percent-not-normalized":"","disabled":_vm.disabled},on:{"input":function($event){return _vm.onInput(_vm.value.id, row.samplePointId, 'humus', $event)}}})]}}:null,(_vm.value.targetValue === 'ph')?{key:"ph",fn:function(ref){
var row = ref.row;
return [_c('NumericInput',{attrs:{"name":"ph","value":row.ph,"disabled":_vm.disabled},on:{"input":function($event){return _vm.onInput(_vm.value.id, row.samplePointId, 'ph', $event)}}})]}}:null,(_vm.value.targetValue === 'soilTexture')?{key:"sand",fn:function(ref){
var row = ref.row;
return [_c('NumericInput',{attrs:{"name":"sand","value":row.sand,"percent":"","disabled":_vm.disabled,"percent-not-normalized":""},on:{"input":function($event){return _vm.onInput(_vm.value.id, row.samplePointId, 'sand', $event)}}})]}}:null,(_vm.value.targetValue === 'soilTexture')?{key:"silt",fn:function(ref){
var row = ref.row;
return [_c('NumericInput',{attrs:{"name":"silt","value":row.silt,"percent":"","disabled":_vm.disabled,"percent-not-normalized":""},on:{"input":function($event){return _vm.onInput(_vm.value.id, row.samplePointId, 'silt', $event)}}})]}}:null,(_vm.value.targetValue === 'soilTexture')?{key:"clay",fn:function(ref){
var row = ref.row;
return [_c('NumericInput',{attrs:{"name":"clay","value":row.clay,"percent":"","disabled":_vm.disabled,"percent-not-normalized":""},on:{"input":function($event){return _vm.onInput(_vm.value.id, row.samplePointId, 'clay', $event)}}})]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }