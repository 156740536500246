<template>
  <div>
    <IxVectorLayer
      :features="fieldFeature"
      :vector-style="styles.field"
      layer-id="field"
      :z-index="1"
    />

    <IxVectorLayer
      :features="features"
      :vector-style="styles.point"
      layer-id="probing-points"
      :z-index="2"
    />

    <IxVectorLayer
      :features="selectedFeatures"
      :vector-style="styles.selected"
      layer-id="selected-probing-points"
      :z-index="3"
    />
  </div>
</template>

<script>
import {probingResultPreview, selectedPoint, selectionStyle} from '@frs/map-styles'

import IxVectorLayer from '@components/map/IxVectorLayer'

export default {
  components: {
    IxVectorLayer
  },
  props: {
    features: {
      type: Array,
      required: true
    },
    selectedFeatures: Array,
    fieldFeature: Array
  },
  computed: {
    styles () {
      return {
        point: probingResultPreview,
        selected: selectedPoint,
        field: selectionStyle
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
