<template lang="html">
  <div>
    <FrsRouterBackLink :target="target">
      <template #text>
        <slot name="back-title" />
      </template>
    </FrsRouterBackLink>

    <h3>
      <slot v-if="$slots.title" name="title" />
    </h3>
    <p>
      <slot v-if="$slots.description" name="description" />
    </p>
    <hr>
  </div>
</template>

<script>
import FrsRouterBackLink from '@frs/components/base/FrsRouterBackLink'

export default {
  components: {
    FrsRouterBackLink
  },
  props: {
    target: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
h3, p {
  margin: 5px;
}
</style>
